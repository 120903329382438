import request from './request';
import mixin from '@/mixin.js';

export default {
  buildURL(url, opts, search) {
    let args = [];

    if (opts.page) {
      args.push(`page=${opts.page}&size=${opts.itemsPerPage}`);
    }

    if (search?.length > 0) {
      args.push(`search=${search}`);
    }

    if (opts.filters) {
      opts.filters.forEach((f) => {
        // console.log('f:', f);
        let field = f.field;
        if (field == 'order') {
          field = 'order__order_no';
        }
        args.push(`${field}${f.operation}${f.value}`);
        // console.log('args', args);
      });
    }

    let sort = [];
    for (let i = 0; i < opts.sortBy?.length; i++) {
      let sign = '';
      if (i < opts.sortDesc?.length && opts.sortDesc[i]) {
        sign += '-';
      }
      let field = opts.sortBy[i].replace('.', '__');

      if (field == 'operator') {
        field = 'operator__username';
      }
      if (field == 'order') {
        field = 'order__order_no';
      }
      if (field == 'tStatus') {
        field = 'status';
      }
      sort.push(sign + field);
    }
    if (sort.length > 0) {
      args.push(`ordering=${sort.join(',')}`);
    }

    if (args.length == 0) {
      return url;
    }

    url = `${url}?${args.join('&')}`;

    return url;
  },

  transform(o) {
    return {
      ...o,
      when_created: mixin.methods.dateFromISO8601(o.when_created),
      shipping_date: mixin.methods.dateFromISO8601(o.shipping_date),
      delivery_date: mixin.methods.dateFromISO8601(o.delivery_date)
    };
  },

  async getShipments(opts, search) {
    let url = `/api/shipments/`;
    url = this.buildURL(url, opts, search);

    let rsp = await request.request(url, 'get', {}, {});
    let records = rsp.data.results.map((r) => this.transform(r));

    return [records, rsp.data.count];
  },

  async getShipment(id) {
    let url = `/api/shipments/${id}/`;

    let rsp = await request.request(url, 'get', {}, {});
    // console.log("getShipment.rsp:", rsp)
    return this.transform(rsp.data);
  },

  async postShipment(shipment) {
    delete shipment['client_ro'];
    console.log('post shipment: ', shipment);
    const result = await request.request(
      `/api/shipments/`,
      'post',
      shipment,
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async patchShipment(shipment) {
    delete shipment['client_ro'];

    console.log('patching shipment: ', shipment);
    let id = shipment.id;
    const result = await request.request(
      `/api/shipments/${id}/`,
      'patch',
      shipment,
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async deleteShipment(shipment) {
    console.log('delete shipment: ', shipment);
    let id = shipment.id;
    const result = await request.request(
      `/api/shipments/${id}/`,
      'delete',
      {},
      {}
    );

    console.log('result: ', result);
    return result;
  },

  async excel(opts, search) {
    console.log('Generate shipments.xlsx');
    let url = `/api/shipment/excel/`;
    url = this.buildURL(url, opts, search);

    console.log('url:', url);

    await request.download(url, 'shipment.xlsx');
  },

  async appendParcels(id, parcelIds) {
    let url = `/api/shipments/${id}/append_parcels/`;
    console.log('appendParcels.url:', url);
    let req = {
      parcel_ids: parcelIds
    };
    const rsp = await request.request(url, 'post', req, {});
    console.log('appendParcels.rsp:', rsp);
    return rsp;
  },

  async removeParcels(id, parcelIds) {
    let url = `/api/shipments/${id}/remove_parcels/`;
    console.log('removeParcels.url:', url);
    let req = {
      parcel_ids: parcelIds
    };
    const rsp = await request.request(url, 'post', req, {});
    console.log('removeParcels.rsp:', rsp);
    return rsp;
  },

  async downloadAttachment(shipment, path) {
    console.log('Downloading: ', path);
    let id = shipment.id;
    const rsp = await request.request(
      `/api/shipments/${id}/download_attachment/?path=${path}`,
      'get',
      {},
      {}
    );
    console.log(rsp);
    return rsp.data;
  },

  async downloadAttachments(shipment, paths) {
    let id = shipment.id;
    const url = `/api/shipments/${id}/download_attachments/`;
    console.log('POST ', url);
    const req = {
      keys: paths
    };
    const rsp = await request.request(url, 'post', req);
    // console.log(rsp);
    return rsp.data;
  },

  async listAttachments(shipment) {
    let url = `/api/shipments/${shipment.id}/attachments/`;
    console.log('url:', url);
    let rsp = await request.request(url, 'get', {}, {});
    return [rsp.data.results, rsp.data.count];
  },

  async deleteAttachment(shipment, attachment) {
    let url = `/api/shipments/${shipment.id}/attachments/${attachment.id}`;
    console.log('url:', url);
    return await request.request(url, 'delete', {}, {});
  },

  async getShipmentSummary(id) {
    let url = `/api/shipments/${id}/attachments/get_shipment_summary/`;
    let ret = await request.request(url, 'get', {}, {});
    return ret.data;
  },

  async createShipmentSummary(id) {
    let url = `/api/shipments/${id}/attachments/create_shipment_summary/`;
    let ret = await request.request(url, 'post', {}, {});
    return ret.data;
  }
};
